import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalService {


private locale$ = new BehaviorSubject<string>('');
selectedlocale$ = this.locale$.asObservable();
constructor() {}

setLocal(locale: any) {
  
  this.locale$.next(locale);
}


}
