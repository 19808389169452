import { Injectable } from '@angular/core';
import { Pack, Product } from 'app/features/integration/serviceIntegration/models/product.model';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShoppingService {
    private subject = new Subject<any>();

    getShoppingList(): Observable<any> {
        return this.subject.asObservable();
    }


    addToPack( action:string,  service: Pack | Product , type:string) {        
        this.subject.next({ service: service  , action :action  , type : type });
    }

  

}