import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';
import {MatFormFieldModule} from '@angular/material/form-field';

import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PreloaderComponent } from './components/preloader/preloader.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ShoppingService } from './sharedServices/shopping.service';

@NgModule({
    declarations:[
        PreloaderComponent
    ] ,
    imports: [
        VerticalLayout1Module,
        MatProgressBarModule,
        VerticalLayout2Module,
        VerticalLayout3Module,
        MatFormFieldModule,
        HorizontalLayout1Module,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        PreloaderComponent,
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module
    ],
    providers:[
        ShoppingService
    ]
})
export class LayoutModule
{
}
