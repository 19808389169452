
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, retry} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: localStorage.getItem('jwt')
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = environment.apiUrl + 'authenticate';
  
/*
  private body = { token: environment.token };
*/
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     Authorization: localStorage.getItem('jwt')
  //   })
  // };

  constructor(private http: HttpClient) { }

 // {{host}}/frontcandidate/validation/8ae2df7c-cacc-4446-90da-5e35c328a501

   verifSubscription(token) : Observable<any>{
    return this.http.patch<any>(`${environment.apiUrl}frontcandidate/validation/${token}` , {});
   }

  changeLangue(params) {
    return this.http.put<any>(`${environment.apiUrl}frontcandidate/locale`, params);
  }
  

  public getAuthenticate(body): Observable<any> {
      return this.http.post<any>(this.authUrl, body);
  }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
       
        return throwError(errorMessage);
    }

    IsAccepted() {
      const need_consent =  Boolean(localStorage.getItem('need_consent')) 
      return need_consent
    }
    
    IsloggedIn() {
      const token = localStorage.getItem('jwt');


      return this.jwtHelper.isTokenExpired(token) 
    }

    loggedIn() {
    const token = localStorage.getItem('jwt');
    return !this.jwtHelper.isTokenExpired(token);
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('jwt');
    localStorage.removeItem('expire_in');
    localStorage.removeItem('status') ;
    localStorage.removeItem('role') ;
    
    
  }



  


}
