import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {MatFormFieldModule } from '@angular/material/form-field'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button'; 
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';


import {MatCheckboxModule} from '@angular/material/checkbox'; 

@NgModule({
    imports     : [
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        TranslateModule,
        MatInputModule,
        MatCheckboxModule
    ],
    exports     : [
       
    ],
    declarations: [
    

    ],
    providers: [{
        provide: { MatDialogRef ,MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS},

        useValue: {hasBackdrop: true}
      }]
})
export class JobZioModule
{
}
