<!-- PROGRESS BAR -->
<fuse-progress-bar  ></fuse-progress-bar> 
<!-- / PROGRESS BAR -->


<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

