import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { CandidateService } from 'app/features/integration/myCV/services/candidate.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit , OnChanges
{  @Input( ) status =''
   @Input( ) apply_to_joboffer =true

    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService ,
        private serviceCandidate:CandidateService,

    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

   
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
  

    ngOnChanges(changes: SimpleChanges): void {
    
          this._fuseNavigationService.getCurrentNavigation().forEach(e => {
             
            if(   this.status === 'CV_ACCEPTED'   && ( e.title === 'joboffers'  ||  e.title === 'recruitingsPreparing'  ||   e.title === 'recruitings'  ||  e.title === 'dashboard'  ||   e.title === 'professional_Info'  || e.title === 'services'  ||  e.title === 'orders' )  ) {
                e.hidden = false
                 
            }
             

        //    else  if(   this.status === 'CV_ACCEPTED' &&  !this.apply_to_joboffer  &&   e.title === 'recruitings' )  {
        //         e.hidden = false
                 
        //     }

            // else  if(   this.status === 'CV_ACCEPTED' &&  this.apply_to_joboffer  &&   e.title === 'recruitings' )  {
            //     e.hidden = true
                 
            // }

          
           else if(   this.status !== 'CV_ACCEPTED'   && (  e.title === 'recruitingsPreparing'   ||  e.title === 'dashboard'  ||   e.title === 'professional_Info'  || e.title === 'services'  ||  e.title === 'orders' )  ){
                e.hidden = false   
            }

            else {
                e.hidden = true
            }
        
            })  
    }
    ngOnInit(): void
    { 
        
      //  //////console.log(this._fuseNavigationService.getCurrentNavigation());
     
    
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
       
        
        //  const status =  localStorage.getItem('status')
        //   this._fuseNavigationService.getCurrentNavigation().forEach(e => {
        //         if(status !== 'CV_ACCEPTED' ) {
        //             if(e.title === 'joboffers' || e.title === 'recruitings' || e.title === 'services' || e.title === 'orders' ) {
        //                 e.hidden = true
        //             }
        //         }   
        //          })

        
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }
}
