import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from 'app/_services/auth.service';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class StatusGuard implements CanActivate {
    currentUser ;
    unsubscribeAll: Subject<any>;
  
    constructor(
        private authservice: AuthService ,
        private router: Router,
       
    ) { 
        this.unsubscribeAll = new Subject();
         

        
    }

    canActivate() {
       
        const status =   localStorage.getItem('status');
        
        if (   status === "CV_ACCEPTED"  ) {                 
            return true;
            
        }


        else {        
            this.router.navigate(['']);
            return false;
        }
    }
}
