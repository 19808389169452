import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopApiComponent } from '@fuse/components/jobZioIntegration/popApi/popApi.component';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor(        private _matDialog: MatDialog
    ) { }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm(message, (e) => {
      if (e) {
        okCallback();
        this.warning('le projet a été enlevé de votre chrono');
      } else { }
    }).setHeader('<em> JobiZeoo </em> ');
  }

  success(message: string , status?) {
  //  alertify.success(message);
   if(status) {
    this._matDialog.open(PopApiComponent, {
      data: {
        mode: 'active',
        msg: 'EMPTY.DONE',
        image: "./assets/icons/jobzioo-icons/check.svg"
      }
    })
   }else {
         alertify.success(message);

   }

  
  }

  warning(message: string) {
    alertify.warning(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
