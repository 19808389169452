import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class CandidateService {

    private backUrl = `${environment.apiUrl}frontcandidate`;

    constructor(private http: HttpClient) {
    }

    downloadCV(code) {        
        return this.http.patch<any>(`${this.backUrl}/cv/download`, {});
    }

    editProfile(data) {
        return this.http.put<any>(`${this.backUrl}/profile`, data);

    }

    hide(mode, data): Observable<any> {
        return this.http.patch<any>(`${this.backUrl}/cv/${mode}/${data}/state`, {});
    }



  //  hide/show experience ( /state)

    resetPassword(mail): Observable<any> {
        return this.http.patch<any>(`${this.backUrl}/resetpasswd/${mail}`, {});
    }

    getDegreeList() {
        return this.http.get<any>(`${this.backUrl}/degrees`);

    }

    getMyCV() {
        return this.http.get<any>(`${this.backUrl}/cv`, {});

    }


    addItem(mode, data): Observable<any> {
        return this.http.post<any>(`${this.backUrl}/cv/${mode}`, data);
    }


    putItem(mode, id, data): Observable<any> {
        return this.http.put<any>(`${this.backUrl}/cv/${mode}/${id}`, data);
    }


    updateProof(mode, id, data): Observable<any> {
        return this.http.post<any>(`${this.backUrl}/cv/${mode}/${id}/proof`, data);
    }

    getItem(mode, code?): Observable<any> {
        if (code) {
            return this.http.get<any>(`${this.backUrl}/cv/${mode}/${code}`);
        } else {
           // //////console.log('vvvv');
            if(mode === 'educationexperience') {
                return this.http.get<any>(`${this.backUrl}/cv/educationsexperiences`);
            }else {
                return this.http.get<any>(`${this.backUrl}/cv/${mode}s`);
            }
           

        }
    }

    updateAttachment(documentType ,data): Observable<any> {
        return this.http.post<any>(`${this.backUrl}/attachement/${documentType}`, data);
    }

    deleteAttachment(documentType): Observable<any> {
        return this.http.delete<any>(`${this.backUrl}/attachement/${documentType}`);
    }

    getModeComment(mode: string, codeItem: string): Observable<any> {
        return this.http.get<any>(`${this.backUrl}/cv/${mode}/${codeItem}/comment`, {});
    }


    getLangaugeList(): Observable<any> {
        return this.http.get<any>(`${this.backUrl}/languages`);
    }

    getLevelList(): Observable<any> {
        return this.http.get<any>(`${this.backUrl}/languagelevels`);
    }

    deleted(mode, id): Observable<any> {
        return this.http.delete<any>(`${this.backUrl}/cv/${mode}/${id}`);
    }


    getLicensDriverList() {
        return this.http.get<any>(`${this.backUrl}/licences`);

    }

    submitCV(): Observable<any> {
        return this.http.put<any>(`${this.backUrl}/cv/pending`, {});
    }


    getNotificationList(params?): Observable<any> {
        return this.http.get<any>(`${this.backUrl}/notifications` , {params});
      }
    
      GetGeneralInfo(): Observable<any> {
        return this.http.get<any>(`${this.backUrl}/informations`);
    }

}
