
import { RouterState } from '@ngxs/router-plugin';

import { PathState } from './path/path.state';



export * from './router';



export * from './path';

/**
 * Import all State here
 */
export const AppState = [
    RouterState,
   
    PathState,
  
   
];
