import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [


            
            {
                id       : 'dashboard',
                title    : 'dashboard',
                translate: 'COMMUN.DASHBOARD',
                type     : 'item',
                icon     : './assets/integration/navbar/dashboard.svg',
                url      : '/',
                
            },
        
            // {
            //     id       : 'GeneralInfo',
            //     title    : 'GeneralInfo',
            //     translate: 'CANDIDATE.GENRALINFO',
            //     type     : 'item',
            //     icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
                
            //     url      : '/CV/genralInfo',
            // },
            {
                id       : 'Profession_Info',
                title    : 'professional_Info',
                translate: 'CANDIDATE.CV_CANDIDATE',
                type     : 'item',
               icon     : './assets/integration/navbar/file.svg',

                url      : '/CV',
            },
            {
                id       : 'jobOffers',
                title    : 'joboffers',
                translate: 'JOBOFFERS.JOBOFFER',
                type     : 'item',
                icon     : './assets/icons/jobzioo-icons/job-offers.svg',
                url      : '/joboffers', 
   
            },

    {
        id: 'recruitings',
        title: 'recruitings',
        translate: 'CANDIDATE.RECRUTING',
        type: 'item',
        url: '/recrutment',
        icon     : './assets/icons/jobzioo-icons/Experiences-list.svg',

        // children: [
            
        //     {
        //         id: 'Recruitings',
        //         title: 'Recruitings',
        //         translate: 'CANDIDATE.RECRUTING',
        //         type: 'item',
        //         url: '/recruitings',
        //         classes :'sub-title'
        //     }
        //     //
        // ]
    },





    {
        id: 'recrutmentPreparing',
        title: 'recruitingsPreparing',
        translate: 'CANDIDATE.RECRUTING_PREPARING',
        type: 'item',
        icon     : './assets/icons/jobzioo-icons/Experiences-list.svg',
        classes :'group',
        url      : '/recrutmentPreparing',
       
    },



            {
                id: 'services',
                title: 'services',
                translate: 'SERVICES.SERVICES',
                type: 'item',
                url: '/services',
                icon     : './assets/integration/navbar/pack.svg',
                // children: [
                //     {
                //         id: 'services',
                //         title: 'services',
                //         translate: 'SERVICES.PRODUCTS',
                //         type: 'item',
                //         url: '/services/products',
                //         classes :'sub-title'
                //     },
                //     {
                //         id: 'packs',
                //         title: 'packs',
                //         translate: 'SERVICES.PACKS',
                //         type: 'item',
                //         url: '/services/packs',
                //         classes :'sub-title'
                //     },
                  
                // ]
            },


    {
        
            id       : 'orders',
            title    : 'orders',
            translate: 'ORDERS.ORDERS',
            type     : 'item',
            icon     : './assets/integration/navbar/order.svg',
            classes :'group',
            url      : '/orders',
           
      
    
        // children : [
        //     {
        //         id       : 'orders',
        //         title    : 'orders',
        //    //     icon     : './assets/icons/jobzioo-icons/Jobtypes-list.svg',
        //         translate: 'ORDERS.ORDERS',
        //         type     : 'item',
        //         url      : '/orders',
        //         classes :'sub-title'
        //     },
        // ]
    },

    
];
