import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { PathState } from 'app/store/path/path.state';
import { Observable } from 'rxjs';
import { TablePath } from '@fuse/components/acces-header/table.path';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Select(PathState.getSelectedPath) Path$: Observable<TablePath>;
  constructor() { }
  path = [];
  curruentPage = '';
  style = '';
  ngOnInit(): void {
    this.Path$.subscribe((data) => {      
       this.curruentPage = data.currentpage;
       this.style = data.style;
       this.path = data.intermediatePaths;
    });

  }
}
