<div class="navbar-header  background-Jobzio" >


    <div class="logo" >
        <a  [routerLink]="['']"  >
          <img  class="logo-icon" src="assets/images/logos/jobzioo.png">
        </a>
          <!-- <span class="logo-text">JobZioo</span> -->
      </div>
    <!-- <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button> 

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>
-->
</div>

<div class="navbar-content  background-Jobzio" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
[ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation [status]="status" [apply_to_joboffer]="apply_to_joboffer" layout="vertical"></fuse-navigation>
</div>

 <div class="footer-footer">
   <span>  {{date}} </span>
   <span>  Jobzioo </span> 
 </div>


